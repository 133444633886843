import { ActivityCategory } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";

export const DEFAULT_START_TIME = 7;
export const DEFAULT_END_TIME = 15.5;

export const daysOfWeekAbbreviations = [
   "sunday_abbrev",
   "monday_abbrev",
   "tuesday_abbrev",
   "wednesday_abbrev",
   "thursday_abbrev",
   "friday_abbrev",
   "saturday_abbrev",
];

export const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export const paySplitFields = ["overtime", "straight", "unpaid"];

export const dateSortOptions = [
   { id: "1", label: "Date - Ascending", value: Order.ASCENDING },
   { id: "2", label: "Date - Descending", value: Order.DESCENDING },
];

export const months = [
   "Jan",
   "Feb",
   "Mar",
   "Apr",
   "May",
   "Jun",
   "Jul",
   "Aug",
   "Sep",
   "Oct",
   "Nov",
   "Dec",
];

export const workDays = {
   "0": false,
   "1": true,
   "2": true,
   "3": true,
   "4": true,
   "5": true,
   "6": false,
};

export const projectSpecificDateSortOptions = [
   { id: "0", label: "All", value: "", show: true },
   { id: "1", label: "Attachments", value: ActivityCategory.PROJECT_ATTACHMENTS, show: true },
   { id: "2", label: "Categories", value: ActivityCategory.PROJECT_COST_CODES, show: true },
   { id: "3", label: "Requests", value: ActivityCategory.PROJECT_PLACEHOLDER, show: true },
   { id: "4", label: "Info", value: ActivityCategory.PROJECT_INFO, show: true },
   { id: "5", label: "Notes", value: ActivityCategory.PROJECT_NOTES, show: true },
   { id: "6", label: "Roles", value: ActivityCategory.PROJECT_ROLES, show: true },
   { id: "7", label: "Tags", value: ActivityCategory.PROJECT_TAGS, show: true },
   { id: "8", label: "Wage Overrides", value: ActivityCategory.PROJECT_WAGE_OVERRIDE, show: true },
];

export const dayNameToNumberMap = {
   sunday: 0,
   monday: 1,
   tuesday: 2,
   wednesday: 3,
   thursday: 4,
   friday: 5,
   saturday: 6,
};

export const numberToDayNameMap = {
   0: "sunday",
   1: "monday",
   2: "tuesday",
   3: "wednesday",
   4: "thursday",
   5: "friday",
   6: "saturday",
};
