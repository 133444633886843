import React, { forwardRef, useImperativeHandle, useRef, useState, useCallback } from "react";
import { Box, Card, FlexList, H2, H3, P, Required } from "@procore/core-react";
import { DynamicToken } from "./dynamic-token";
import "./dynamic-token-styles.css";
import { Error } from "@procore/core-icons";

export interface DynamicTokenEditorRef {
   getContent: () => { subject: string; content: string };
}

interface DynamicTokenEditorProps {
   tokens: any[];
   onContentChange?: (content: { subject: string; content: string }) => void;
   initialContent: { subject: string; content: string };
   I18n: any; // Replace with proper type for your I18n object
   errorFields?: string[];
}

export const DynamicTokenEditor = forwardRef<DynamicTokenEditorRef, DynamicTokenEditorProps>(
   ({ tokens, onContentChange, initialContent, I18n, errorFields }, ref) => {
      const subjectRef = useRef<HTMLDivElement>(null);
      const contentRef = useRef<HTMLDivElement>(null);
      const [range, setRange] = useState<Range | null>(null);

      useImperativeHandle(ref, () => ({
         getContent: () => ({
            subject: subjectRef.current?.innerHTML ?? "",
            content: contentRef.current?.innerHTML ?? "",
         }),
      }));

      const updateRange = useCallback(() => {
         const selection = window.getSelection();
         if (selection && selection.rangeCount > 0) {
            setRange(selection.getRangeAt(0).cloneRange());
         }
      }, [range]);

      const insertToken = useCallback(
         (token: any) => {
            if (!range) return;

            const tokenSpan = document.createElement("span");
            tokenSpan.contentEditable = "false";
            tokenSpan.className = "lc__canned-token";
            tokenSpan.dataset.token = JSON.stringify(token);
            tokenSpan.textContent = `{ ${token.name} }`;

            range.deleteContents();
            range.insertNode(tokenSpan);

            const newRange = document.createRange();
            newRange.setStartAfter(tokenSpan);
            newRange.setEndAfter(tokenSpan);

            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(newRange);

            setRange(newRange.cloneRange());

            // Trigger content change
            handleContentChange();
         },
         [range],
      );

      const handleContentChange = useCallback(() => {
         if (onContentChange) {
            onContentChange({
               subject: subjectRef.current?.innerHTML ?? "",
               content: contentRef.current?.innerHTML ?? "",
            });
            updateRange();
         }
      }, [onContentChange]);

      const handleKeyDown = useCallback(
         (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
               event.preventDefault();
               const selection = window.getSelection();
               if (selection && selection.rangeCount > 0) {
                  const range = selection.getRangeAt(0);
                  const br = document.createElement("br");
                  range.deleteContents();
                  range.insertNode(br);
                  range.setStartAfter(br);
                  range.setEndAfter(br);
                  range.collapse(false);
                  selection.removeAllRanges();
                  selection.addRange(range);
               }
               handleContentChange();
               return false;
            }
         },
         [handleContentChange],
      );

      return (
         <>
            <Box marginTop="md">
               <Card>
                  <Box padding="md">
                     {/* This can/should be extended to be a prop for better reuse */}
                     <H2>
                        {I18n.t(
                           "views.company.workforce_planning.communications.edit_assignment_alert",
                        )}
                     </H2>
                     <FlexList space="xs">
                        <P>
                           <strong>
                              {I18n.t("views.company.workforce_planning.communications.subject")}
                           </strong>
                        </P>
                        <Required />
                     </FlexList>
                     <div
                        ref={subjectRef}
                        contentEditable
                        className={`dynamic-token-input ${
                           errorFields?.includes("subject") ? "error-border" : ""
                        }`}
                        onInput={handleContentChange}
                        onFocus={updateRange}
                        onBlur={updateRange}
                        dangerouslySetInnerHTML={{ __html: initialContent.subject }}
                        data-testid={"dynamic-token-subject-input"}
                     />
                     {errorFields?.includes("subject") && (
                        <div className="error-container">
                           <Error size="sm" className="error-icon" />
                           <span className="error-text">
                              {I18n.t(
                                 "views.company.workforce_planning.validations.required_field",
                              )}
                           </span>
                        </div>
                     )}
                     <FlexList space="xs">
                        <P>
                           <strong>
                              {I18n.t(
                                 "views.company.workforce_planning.communications.alert_content",
                              )}
                           </strong>
                        </P>
                        <Required />
                     </FlexList>
                     <div
                        ref={contentRef}
                        contentEditable
                        className={`dynamic-token-text-area ${
                           errorFields?.includes("content") ? "error-border" : ""
                        }`}
                        onInput={handleContentChange}
                        onFocus={updateRange}
                        onBlur={updateRange}
                        onKeyDown={handleKeyDown}
                        dangerouslySetInnerHTML={{ __html: initialContent.content }}
                        data-testid={"dynamic-token-content-input"}
                     />
                     {errorFields?.includes("content") && (
                        <div className="error-container">
                           <Error size="sm" className="error-icon" />
                           <span className="error-text">
                              {I18n.t(
                                 "views.company.workforce_planning.validations.required_field",
                              )}
                           </span>
                        </div>
                     )}
                  </Box>
               </Card>
            </Box>
            <Box paddingTop="md">
               <Card>
                  <Box padding="md">
                     <H3>
                        {I18n.t(
                           "views.company.workforce_planning.communications.tokens.dynamic_tokens",
                        )}
                     </H3>
                     <P>
                        {I18n.t(
                           "views.company.workforce_planning.communications.tokens.description",
                        )}
                     </P>
                     <FlexList wrap="wrap">
                        {tokens.map((token: any) => (
                           <DynamicToken
                              key={token.id}
                              label={token.name}
                              onClick={() => insertToken(token)}
                           />
                        ))}
                     </FlexList>
                  </Box>
               </Card>
            </Box>
         </>
      );
   },
);
