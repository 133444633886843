import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import type { CustomField } from "@/react/prop-types";
import { processCustomFields } from "@/react/shared/custom-field-utils";
import type { AssignmentFormValues, OverTimeRates } from "./assignment/types";
import type { RequestFormValues } from "./request/types";
import type { BaggageTimeKey, CommonPayloadForRequestAndAssignment, ProjectOptions } from "./types";
import { timeOptions } from "@/lib/utils/timezones";
import { dayNameToNumberMap, numberToDayNameMap } from "@/react/shared/constants";

export const getCommonFieldsForRequestAndAssignment = (
   values: AssignmentFormValues | RequestFormValues,
   customFields: CustomField[] | null,
): CommonPayloadForRequestAndAssignment => ({
   project_id: values.project!.id,
   category_id: values.category?.id ?? null,
   subcategory_id: values.subcategory?.id ?? null,
   start_day: getDetachedDay(new Date(values.start_date!)),
   end_day: getDetachedDay(new Date(values.end_date!)),
   start_time: values.assignment_by_time ? values.start_time.id : null,
   end_time: values.assignment_by_time ? values.end_time.id : null,
   percent_allocated: values.assignment_by_allocation ? values.percent_allocated : null,
   status_id: values.status?.id ?? null,
   work_days: values.work_days,
   custom_fields: customFields ? processCustomFields(customFields, values) : {},
   instruction_text: values.instruction_text ?? null,
   work_scope_text: values.work_scope_text ?? null,
   comments: values.comments
      ? [
           {
              author_id: values.author_id!,
              content: values.comments,
           },
        ]
      : undefined,
});

export const getTimeOptionsFromProject = (
   selectedItem: ProjectOptions | undefined,
   defaultTime: number,
   timeKey: BaggageTimeKey,
) => {
   if (selectedItem) {
      const timeId = selectedItem.baggage[timeKey] ?? defaultTime;
      const timeLabel = timeOptions.find(({ id }) => id === timeId)?.label;
      return { id: timeId, label: timeLabel };
   }
};

export const getTotalHours = (endTime: number, startTime: number): number => {
   const totalHours = endTime >= startTime ? endTime - startTime : 24 + endTime - startTime;
   return totalHours;
};

// Function to transform overtime rates to use numeric keys
export const transformOvertimeRates = (overtimeRates: OverTimeRates) => {
   const transformedRates: { [key: number]: number } = {};

   for (const [dayName, rate] of Object.entries(overtimeRates)) {
      const dayNumber =
         dayNameToNumberMap[dayName.toLowerCase() as keyof typeof dayNameToNumberMap];
      // Check if dayNumber is not undefined (include 0)
      if (dayNumber !== undefined) {
         // If rate is null or undefined, set it to 0
         transformedRates[dayNumber] = rate ?? 0;
      }
   }

   return transformedRates;
};

// Function to reverse transform overtime rates to use day names
export const reverseTransformOvertimeRates = (transformedRates: { [key: number]: number }) => {
   const originalRates: { [key: string]: number } = {};

   for (const [dayNumber, rate] of Object.entries(transformedRates)) {
      const dayName = numberToDayNameMap[Number(dayNumber) as keyof typeof numberToDayNameMap];

      // Check if dayName is defined in the map
      if (dayName !== undefined) {
         // Assign to the original structure using day names
         originalRates[dayName] = rate ?? 0;
      }
   }

   return originalRates;
};
