import type { GanttProject } from "./prop-types";
import { TaskType } from "./prop-types";
import type { TimeOff } from "@laborchart-modules/common";
import { v4 } from "uuid";

export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// Gets all the permutations of the truthy work-days in the format [[], ['Su'], ['Mo'], ['Su', 'Mo'], etc...]
const workdayPermutations = (() => {
   const n = DAYS.length;
   const permutations = [];
   const totalPermutations = 1 << n; // 2^n permutations

   for (let i = 0; i < totalPermutations; i++) {
      const permutation = [];
      for (let j = 0; j < n; j++) {
         // Check if the j-th bit in the integer i is set
         const isWorkday = (i & (1 << j)) !== 0;
         const day = DAYS[j % n];
         if (isWorkday) permutation.push(day);
      }
      permutations.push(permutation);
   }

   return permutations;
})();

/**
 * Function that returns unqiue calendar id for assginments and
 * name based on working days for requests (requests do not have an assigned person, so time off is not applicable)
 */
export function getCalendarName(task: any, taskType: TaskType): string {
   if (taskType === TaskType.ASSIGNMENT) {
      // For assignments we need ID that's unique for all assignments and assigned people
      return `${task.id}_${task.resource_id}`;
   } else {
      // Give an object like {"0": boolean, "1": boolean, "2": boolean, "3": boolean, "4": boolean, "5": boolean, "6": boolean}
      // return a string like "Su,M,Tu,W,Th,F,Sa" for the true values
      return Object.entries(task.work_days)
         .filter(([_, isWorkday]) => isWorkday)
         .map(([index]) => DAYS[Number(index)])
         .join(",");
   }
}

export function getWorkingDays(permutation: string[]) {
   return permutation.map((day: any) => ({
      recurrentStartDate: `on ${day} at 0:00`,
      recurrentEndDate: `on ${day} at 23:59`,
      isWorking: true,
   }));
}

// Gets used by the bryntum gantt so we can add custom styling to non-working days.
// For an assignment/request task to use one of these calendars, they must use the calendar id
// as a value for the 'calendar' property on the task. To get the proper calendar id value,
// the task will call the getCalendarName that's defined above.
const calendarsData = workdayPermutations.map((permutation) => {
   const daysString = permutation.join(",");
   const workingDays = getWorkingDays(permutation);

   return {
      id: daysString,
      name: daysString,
      cls: "non-working-day",
      unspecifiedTimeIsWorking: false,
      intervals: workingDays,
   };
});

export const getCalendarsGanttData = (ganttData: any, isTimeoffReasonAvailable: boolean) => {
   const calendarsWithTimeOff =
      ganttData?.projects.flatMap((project: GanttProject) => {
         const assignmentCalendars = project.assignments.map((assignment: any) => {
            const person = ganttData.people.find(
               (person: any) => person.id == assignment.resource_id,
            ) as any;
            const getFormattedDateString = (date: number): string => {
               const dateStr = String(date);

               return dateStr.slice(0, 4) + "-" + dateStr.slice(4, 6) + "-" + dateStr.slice(6);
            };

            const timeOffs: TimeOff[] = (person?.time_off ?? []).flatMap((timeOff: TimeOff) => {
               return timeOff.instances.map((instance: any) => {
                  return {
                     isWorking: false,
                     startDate: getFormattedDateString(instance.start_day + 100), // adding 100 to the number date convert months [00, ..., 11] to [01, ..., 12]
                     endDate: getFormattedDateString(instance.end_day + 101), // adding 101 to the number date convert months [00, ..., 11] to [01, ..., 12] and add 1 day to the end date to match Bryntum date format
                     startTime: timeOff.batch_start_time,
                     endTime: timeOff.batch_end_time,
                     cls: "gantt-time-off",
                     name: isTimeoffReasonAvailable ? timeOff.reason : "",
                     repeat: timeOff.repeat,
                     applyToSaturday: timeOff.apply_to_saturday,
                     applyToSunday: timeOff.apply_to_sunday,
                     isPaid: timeOff.is_paid,
                     id: `${getFormattedDateString(
                        instance.start_day + 100,
                     )}_${getFormattedDateString(instance.end_day + 101)}__${v4()}`,
                  };
               });
            });
            const assignmentWorkDays = DAYS.filter((_, index) => assignment.work_days[index]);
            const workingDaysIntervals = getWorkingDays(assignmentWorkDays);

            return {
               id: `${assignment.id}_${assignment.resource_id}`,
               name: `${assignment.id}_${assignment.resource_id}`,
               cls: "non-working-day",
               unspecifiedTimeIsWorking: false,
               intervals: [...timeOffs, ...workingDaysIntervals],
            };
         });

         return assignmentCalendars;
      }) ?? [];

   return [...calendarsData, ...calendarsWithTimeOff];
};

// OPTIONAL FORMAT 1
// intervals: [
//    {
//       recurrentStartDate: 'on Sat at 0:00',
//       recurrentEndDate: 'on Mon at 0:00',
//       isWorking: false
//    }
// ]

// OPTIONAL FORMAT 2
// intervals: [
//    {
//       startDate: '2022-08-07',
//       endDate: '2022-08-11',
//       isWorking: false
//    },
//    {
//       startDate: '2022-08-18',
//       endDate: '2022-08-20',
//       isWorking: false
//    }
// ]
