import { Button, Modal, Select, RadioButton } from "@procore/core-react";
import type { ReactNode } from "react";
import React from "react";
import { useState } from "react";
import type { Root } from "react-dom/client";
import { createRoot } from "react-dom/client";

interface ModalInput {
   title: string;
   body?: string | ReactNode;
   action: (modalState?: any) => void;
}

export enum GanttModalType {
   DeleteConfirmation,
   ExportView,
}

type ExportModal = {
   exportType: "pdf" | "csv";
   exportPageFormat: "portrait" | "landscape";
   exportPageSize: string;
};

const PAGE_SIZES = [
   { id: 0, value: "A3" },
   { id: 1, value: "A4" },
   { id: 2, value: "A5" },
   { id: 3, value: "Letter" },
   { id: 4, value: "Legal" },
];

export const showGanttModal = ({
   title,
   body,
   action,
   type,
   modalId,
}: ModalInput & { type: GanttModalType; modalId: string }) => {
   let modalContainer: (Element & { _reactRoot?: Root }) | null = document.querySelector(
      `#${modalId}`,
   );

   if (!modalContainer) {
      modalContainer = document.createElement("div");
      modalContainer.setAttribute("id", modalId);
      document.body.appendChild(modalContainer);
   }

   modalContainer._reactRoot?.unmount();
   modalContainer._reactRoot = createRoot(modalContainer);

   if (type === GanttModalType.DeleteConfirmation) {
      modalContainer._reactRoot.render(
         <DeleteConfirmationModal title={title} body={body} action={action} />,
      );
   } else if (type === GanttModalType.ExportView) {
      modalContainer._reactRoot.render(
         <ViewExportModal title={title} body={body} action={action} />,
      );
   }
};

const DeleteConfirmationModal = ({ title, body, action }: ModalInput) => {
   const [open, setOpen] = useState(true);

   return (
      <Modal open={open} onClickOverlay={() => setOpen(false)} onClose={() => setOpen(false)}>
         <Modal.Header onClose={() => setOpen(false)}>{title ?? ""}</Modal.Header>
         <Modal.Body>{body}</Modal.Body>
         <Modal.Footer>
            <Modal.FooterButtons>
               <Button variant="tertiary" onClick={() => setOpen(false)}>
                  Cancel
               </Button>
               <Button
                  variant="primary"
                  onClick={() => {
                     setOpen(false);
                     if (action) action();
                  }}
               >
                  Delete
               </Button>
            </Modal.FooterButtons>
         </Modal.Footer>
      </Modal>
   );
};

const ViewExportModal = ({ title, action }: ModalInput) => {
   const [open, setOpen] = useState(true);
   const [modalState, setModalState] = useState<ExportModal>({
      exportType: "csv",
      exportPageFormat: "portrait",
      exportPageSize: "A4",
   });

   return (
      <Modal
         open={open}
         onClickOverlay={() => setOpen(false)}
         onClose={() => setOpen(false)}
         className="exportModal"
      >
         <Modal.Header onClose={() => setOpen(false)} style={{ borderBottom: "1px solid #D6DADC" }}>
            {title}
         </Modal.Header>
         <Modal.Body
            style={{ maxWidth: "100%", padding: "32px", borderBottom: "1px solid #D6DADC" }}
         >
            <div className="exportModalSelectorsBlock" style={{ marginBottom: "16px" }}>
               <label className="modalExportLabel">
                  <h3 className="filterName">Export file type</h3>
                  <RadioButton
                     checked={modalState.exportType === "pdf"}
                     onChange={() => setModalState({ ...modalState, exportType: "pdf" })}
                     style={{ display: "none" }}
                  >
                     PDF
                  </RadioButton>
                  <RadioButton
                     checked={modalState.exportType === "csv"}
                     onChange={() => setModalState({ ...modalState, exportType: "csv" })}
                  >
                     CSV
                  </RadioButton>
               </label>
               <label className="modalExportLabel" style={{ display: "none" }}>
                  <h3 className="filterName">Page format</h3>
                  <RadioButton
                     checked={modalState.exportPageFormat === "portrait"}
                     onChange={() => setModalState({ ...modalState, exportPageFormat: "portrait" })}
                     disabled={modalState.exportType === "csv"}
                  >
                     Portrait
                  </RadioButton>
                  <RadioButton
                     checked={modalState.exportPageFormat === "landscape"}
                     onChange={() =>
                        setModalState({ ...modalState, exportPageFormat: "landscape" })
                     }
                     disabled={modalState.exportType === "csv"}
                  >
                     Landscape
                  </RadioButton>
               </label>
            </div>
            <div className="exportModalSelectorsBlock">
               <label className="modalExportLabel" style={{ display: "none" }}>
                  <h3 className="filterName">Page size</h3>
                  <Select
                     onSelect={({ item }) => {
                        setModalState({
                           ...modalState,
                           exportPageSize: PAGE_SIZES.find((size) => size.value === item)!.value,
                        });
                     }}
                     label={modalState.exportPageSize}
                     disabled={modalState.exportType === "csv"}
                     className="ganttFilterMultiselect"
                  >
                     {PAGE_SIZES.map((item) => (
                        <Select.Option
                           key={item.id}
                           value={item.value}
                           selected={item.value === modalState.exportPageSize}
                        >
                           {item.value}
                        </Select.Option>
                     ))}
                  </Select>
               </label>
            </div>
         </Modal.Body>
         <Modal.Footer>
            <Modal.FooterButtons>
               <Button variant="tertiary" onClick={() => setOpen(false)}>
                  Cancel
               </Button>
               <Button
                  variant="primary"
                  onClick={() => {
                     setOpen(false);
                     action(modalState);
                  }}
               >
                  Export
               </Button>
            </Modal.FooterButtons>
         </Modal.Footer>
      </Modal>
   );
};
