import * as React from "react";
import { Tearsheet } from "@procore/core-react";
import type { TableApi } from "@procore/data-table";
import { CreatePersonTearsheet } from "./create-person-tearsheet";
import { PersonDetailTearsheet } from "./person-detail-tearsheet-content";

type PersonTearsheetState = OpenState | ClosedState;
type OpenState = { isOpen: true } & (CreatePerson | PersonDetail);
type ClosedState = { isOpen: false; show: null };
type CreatePerson = { show: "create-person" };
type PersonDetail = { show: "person-detail"; personId: string };

type CreatePersonAction = { type: "open-create-person" };
type PersonDetailAction = {
   type: "open-person-detail";
   personId: string;
};
type CloseAction = { type: "close" };

export type PersonTearsheetActions = CreatePersonAction | PersonDetailAction | CloseAction;

function reducer(
   _state: PersonTearsheetState,
   action: PersonTearsheetActions,
): PersonTearsheetState {
   switch (action.type) {
      case "open-create-person": {
         return {
            isOpen: true,
            show: "create-person",
         };
      }
      case "open-person-detail": {
         return {
            isOpen: true,
            show: "person-detail",
            personId: action.personId,
         };
      }
      case "close": {
         return {
            isOpen: false,
            show: null,
         };
      }
   }
}

const initialState: PersonTearsheetState = {
   isOpen: false,
   show: null,
};

const PersonTearsheetContext = React.createContext<
   (PersonTearsheetState & { dispatch: React.Dispatch<PersonTearsheetActions> }) | null
>(null);

export function PersonTearsheetProvider({
   children,
   peopleTableApi,
}: Readonly<{
   children: React.ReactNode;
   peopleTableApi: TableApi | undefined;
}>) {
   const [state, dispatch] = React.useReducer(reducer, initialState);
   return (
      <PersonTearsheetContext.Provider value={{ ...state, dispatch }}>
         {children}
         <PersonTearsheet peopleTableApi={peopleTableApi} />
      </PersonTearsheetContext.Provider>
   );
}

export function usePersonTearsheet() {
   const context = React.useContext(PersonTearsheetContext);

   if (!context) {
      throw Error(
         "'usePersonTearsheet' is supposed to be used in the component that is a child of 'PersonTearsheetProvider'.",
      );
   }

   return context;
}

export function PersonTearsheet(props: Readonly<{ peopleTableApi: TableApi | undefined }>) {
   const state = usePersonTearsheet();
   const isPersonDetailOpen = state.show === "person-detail";
   const isCreatePersonOpen = state.show === "create-person";

   const handleClose = () => state.dispatch({ type: "close" });

   const onPersonCreated = (personId: string) => {
      state.dispatch({ type: "open-person-detail", personId });
      props.peopleTableApi?.refreshServerSide({});
   };

   return (
      <Tearsheet open={state.isOpen} onClose={handleClose}>
         {isCreatePersonOpen && (
            <CreatePersonTearsheet onPersonCreated={onPersonCreated} onClose={handleClose} />
         )}
         {isPersonDetailOpen && <PersonDetailTearsheet personId={state.personId} />}
      </Tearsheet>
   );
}

export function onCreatePersonClick(dispatch: React.Dispatch<PersonTearsheetActions>) {
   dispatch({ type: "open-create-person" });
}

export function onPersonDetailClick(dispatch: React.Dispatch<PersonTearsheetActions>, id: string) {
   dispatch({ type: "open-person-detail", personId: id });
}
