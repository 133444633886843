import React, { useEffect, useState } from "react";
import type { PersonDetailTearsheetProps } from "./types";
import type { Tab } from "@/react/prop-types";
import { TearsheetTabs } from "@/react/prop-types";
import { H1, Page } from "@procore/core-react";
import { CustomTabs } from "@/react/shared/components/custom-tabs";

//adding more objects to this array will automatically start rendering other tabs, just maintain unique ID and labels.
const PERSON_DETAILS_TABS: Tab[] = [{ id: 0, label: TearsheetTabs.OVERVIEW, visible: true }];

// more attributes will come as a prop once we move to the actual implementation of the component
export const PersonDetailTearsheet: React.FC<PersonDetailTearsheetProps> = ({ personId }) => {
   useEffect(() => {
      console.log("Person Details API call can be configured here");
   }, [personId]);
   const [activeTab, setActiveTab] = useState(0);
   return (
      <Page>
         <Page.Main
            style={{
               width: "952px",
               display: "flex",
               flexDirection: "column",
            }}
         >
            <Page.Header>
               <Page.Title>
                  {/* This will be replaced by actual person Name in upcoming stories */}
                  <H1>Person Details</H1>
               </Page.Title>
               <CustomTabs
                  tabs={PERSON_DETAILS_TABS}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
               />
            </Page.Header>
         </Page.Main>
      </Page>
   );
};
